var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "header-left" },
      [
        _c("SiderTrigger", {
          attrs: { collapsed: _vm.collapsed },
          on: { change: _vm.handleCollpasedChange },
        }),
        _vm._t("left"),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "header-right" },
      [_vm._t("right"), _c("UserManage")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-aside",
        {
          staticStyle: {
            height: "100vh",
            width: "auto",
            "background-color": "#1B1D46",
          },
        },
        [
          _c("div", { staticClass: "logo" }),
          _c("Menu", {
            attrs: { collapsed: _vm.collapsed, "menu-list": _vm.menuList },
          }),
        ],
        1
      ),
      _c(
        "el-container",
        { staticClass: "layout-right" },
        [
          _c(
            "el-header",
            [
              _c(
                "Header",
                {
                  attrs: { collapsed: _vm.collapsed },
                  on: { "trigger-change": _vm.handleCollapsedChange },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tag-nav-container",
                      attrs: { slot: "left" },
                      slot: "left",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "tag-nav-wrapper" },
                        [
                          _c("TagsNav", {
                            attrs: { value: _vm.$route, list: _vm.tagNavList },
                            on: {
                              input: _vm.handleClick,
                              "on-close": _vm.handleCloseTag,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-main",
            [
              _c(
                "el-scrollbar",
                {
                  attrs: {
                    native: false,
                    "wrap-class": "content-scrollbar",
                    "view-class": "content-container",
                    noresize: false,
                    tag: "div",
                  },
                },
                [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "content-container container" },
                      [_c("keep-alive", [_c("router-view")], 1)],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("resetModal", {
        attrs: { config: _vm.resetModalConfig },
        on: { closeModal: _vm.closeResetModal },
      }),
      _c("theme", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        attrs: {
          themeColor: _vm.theme.themeColor,
          siderColor: _vm.theme.siderColor,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-progress", {
        attrs: {
          "stroke-width": 10,
          percentage: _vm.level,
          format: _vm.format,
          color: _vm.colors,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
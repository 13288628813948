<template>
  <div>
    <template v-for="(item, index) in list">
      <!-- 标题 -->
      <el-submenu :key="index" :index="item.id" v-if="item.children&&item.children.length > 0">
        <template slot="title">
          <i :class="item.functionIcon || 'el-icon-folder'"></i>
          <span slot="title">{{ item.functionName }}</span>
        </template>
        <subItem :list="item.children"></subItem>
        <!-- <el-menu-item-group>
        </el-menu-item-group> -->
      </el-submenu>
      <!-- 选项 -->
      <template v-else>
        <el-menu-item v-if="item.functionName!='首页'" :key="index" @click="customerUrl(item.functionUrl)" :index="(item.functionUrl)? ((item.functionUrl).startsWith('http')?'':item.functionUrl):''">
          <i :class="item.functionIcon || 'el-icon-tickets'"></i>
          <span slot="title">{{ item.functionName }}</span>
        </el-menu-item>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'subItem',
  props: {
    list: Array,
  },
  methods: {
    customerUrl(functionUrl) {
      if (functionUrl.startsWith('http')) {
        window.open(functionUrl);
      }
    },
  },
};

</script>

<style lang="less" scoped>
.el-menu-item,
/deep/.el-submenu__title {
  height: 45px;
  line-height: 45px;
}
/deep/i:before {
    color: #fff!important;
}
// .el-menu-item.is-active {
//   background-color: #5895ff !important;
// }
</style>

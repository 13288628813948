<template>
  <div>
    <el-progress :stroke-width="10" :percentage="level" :format="format" :color="colors"></el-progress>
  </div>
</template>

<script>
import request from '../../utils/request';

export default {
  props: {
    password: String,
  },
  data() {
    return {
      level: 0,
      colors: [
        { color: 'red', percentage: 30 },
        { color: '#e6a23c', percentage: 50 },
        { color: '#1989fa', percentage: 70 },
        { color: '#5cb87a', percentage: 100 },
      ],
      pwdRule: {},
      isChecked: false,
      errorMsg: '密码安全级别过低，请修改后再提交',
      reject: '',
    };
  },
  watch: {
    password(val) {
      this.getLevel(val);
    },
  },
  created() {
    this.getPwdRule();
  },
  methods: {
    format(percentage) {
      if (percentage <= 16) {
        return '极低';
      }
      if (percentage <= 33) {
        return '低';
      }
      if (percentage > 33 && percentage < 100) {
        return '中';
      }
      if (percentage === 100) {
        return '高';
      }
    },
    getLevel(pwd) {
      // eslint-disable-next-line no-eval
      const reg = eval(this.pwdRule.weak) || /^.{6,16}$/; // 初级
      // eslint-disable-next-line no-eval
      const reg1 = eval(this.pwdRule.medium) || /^(?=.{6,16})[0-9A-Za-z]*[^0-9A-Za-z][0-9A-Za-z]*$/; // 中级
      // eslint-disable-next-line no-eval
      const reg2 = eval(this.pwdRule.strong) || /^(?=.{6,16})([0-9A-Za-z]*[^0-9A-Za-z][0-9A-Za-z]*){2,}$/; // 高级
      // eslint-disable-next-line no-eval
      const minReg = this.pwdRule.min ? eval(this.pwdRule.min) : /^.{6,16}$/; // 最低
      if (pwd.length === 0) {
        this.level = 0;
        return;
      }
      if (reg2.test(pwd)) {
        // 高级密码
        this.level = 100;
      } else if (reg1.test(pwd)) {
        // 中级密码
        this.level = 66;
      } else if (reg.test(pwd)) {
        // 低级密码
        this.level = 33;
      } else {
        this.level = 16;
      }
      if (minReg.test(pwd)) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      console.log(this.isChecked);
      this.$emit('change', {
        words: this.pwdRule.words,
        isChecked: this.isChecked,
        errorMsg: this.pwdRule.reject || this.errorMsg,
      });
    },
    /**
     * 获取密码验证规则
     */
    getPwdRule() {
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'password_strength',
      }, { loginUserToken: this.$store.state.app.userToken }).then((res) => {
        if (res.success) {
          res.result.forEach((item) => {
            this.pwdRule[item.dictCode] = item.dictValue;
          });
          this.$emit('change', {
            words: this.pwdRule.words,
            isChecked: this.isChecked,
            errorMsg: this.pwdRule.reject || this.errorMsg,
          });
        }
      });
    },
  },
};
</script>

<style></style>

<template>
  <el-container>
    <el-aside style="height: 100vh; width: auto; background-color:#1B1D46">
      <div class="logo"></div>
      <Menu :collapsed="collapsed" :menu-list="menuList" />
    </el-aside>

    <el-container class="layout-right">
      <el-header>
        <Header @trigger-change="handleCollapsedChange" :collapsed="collapsed">
          <!-- <Breadcrumb slot="left" /> -->
          <div slot="left" class="tag-nav-container">
            <div class="tag-nav-wrapper">
              <TagsNav
                :value="$route"
                @input="handleClick"
                :list="tagNavList"
                @on-close="handleCloseTag"
              />
            </div>
          </div>
        </Header>
      </el-header>
      <el-main>
        <!-- <div class="tag-nav-wrapper">
          <TagsNav
            :value="$route"
            @input="handleClick"
            :list="tagNavList"
            @on-close="handleCloseTag"
          />
        </div> -->
        <el-scrollbar
          :native="false"
          wrap-class="content-scrollbar"
          view-class="content-container"
          :noresize="false"
          tag="div"
        >
          <div class="content">
            <div class="content-container container">
              <keep-alive >
                <router-view />
              </keep-alive>
            </div>
          </div>
        </el-scrollbar>
        <!-- <el-footer>
          <Footer />
        </el-footer> -->
      </el-main>
    </el-container>
    <resetModal :config="resetModalConfig" @closeModal="closeResetModal"/>
    <theme :themeColor="theme.themeColor" :siderColor="theme.siderColor" v-show="false"/>
  </el-container>
</template>

<script>
import { mapMutations } from 'vuex';
import Storage from '../../utils/storage';
import { getNewTagList, getMenuopen, routeEqual } from '../../utils';
import Menu from './menu';
import Header from './header';
// import Breadcrumb from './breadcrumb';
import TagsNav from './tags-nav';
import resetModal from './reset_password';
// import Footer from './footer';
import Theme from '../../views/config_center/view/theme';

export default {
  components: {
    Menu,
    Header,
    // Breadcrumb,
    TagsNav,
    resetModal,
    Theme,
    // Footer,
  },
  name: 'Layout',
  created() {
    this.isFirstLogin();
  },
  data() {
    return {
      collapsed: false,
      keepAlive: false,
      theme: {
        themeColor: Storage.l.get('themeColor') || '#409eff',
        siderColor: Storage.l.get('siderColor') || 'rgb(27, 29, 70)',
      },
      resetModalConfig: {
        visible: false,
      },
    };
  },
  watch: {
    $route(newRoute) {
      const openNames = getMenuopen(newRoute, this.menuList);
      this.$store.commit('menus/setopenMenus', openNames);
      const {
        name, query, params, meta,
      } = newRoute;
      // 匹配接口的 菜单名字
      const { tabNameFromMenuArr } = this.$store.state.menus;
      const tabNameFindObj = tabNameFromMenuArr.find((a) => a.functionUrl === newRoute.fullPath) || {};
      meta.title = tabNameFindObj.functionName || '';
      document.title = meta.title;
      const tagList = JSON.parse(JSON.stringify(this.tagNavList));
      this.addTag({
        route: {
          name,
          query,
          params,
          meta,
        },
        type: 'push',
      });
      // this.setBreadCrumb(newRoute);
      this.currentName = name;
      this.keepAlive = tagList.map((item) => item.name).includes(name);
      this.setTagNavList(getNewTagList(this.tagNavList, newRoute));
      // this.$refs.sideMenu.updateOpenName(newRoute.path);
    },
  },
  mounted() {
    this.setTagNavList();
    // this.setHomeRoute(routers);
    const {
      name, params, query, meta,
    } = this.$route;
    // 匹配接口的 菜单名字
    const { tabNameFromMenuArr } = this.$store.state.menus;
    const tabNameFindObj = tabNameFromMenuArr.find((a) => a.functionUrl === this.$route.fullPath) || {};
    meta.title = tabNameFindObj.functionName || '';
    document.title = meta.title;

    this.addTag({
      route: {
        name, params, query, meta,
      },
    });
  },
  computed: {
    tagNavList() {
      return this.$store.state.app.tagNavList;
    },
    key() {
      return this.$route.path + Math.random();
    },
    menuList() {
      const user = Storage.l.get('userInfo') ? JSON.parse(Storage.l.get('userInfo')) : {};
      const menus = this.$store.state.menus.menusName;
      const newArray = [];
      menus.forEach((now, index) => {
        newArray[index] = now;
        if (newArray[index].children && now.children) {
          // 渠道分利 海普终端只有 sysadmin 账号可以看见
          newArray[index].children = now.children.filter((item) => {
            if (user.userName === 'sysadmin') {
              return !item.auth;
            }
            return !item.auth && item.functionCode !== 'CRM20230313000000005';
          });
        }
      });
      return newArray;
      // return this.$store.state.menus.menusName
    },
  },
  methods: {
    ...mapMutations([
      'setBreadCrumb',
      'setTagNavList',
      'addTag',
      'setLocal',
      'setHomeRoute',
      'closeTag',
    ]),
    /**
     * 判断是否是首次登录，首次登录需修改密码
     */
    isFirstLogin() {
      const resetPassword = Storage.s.get('resetPassword');
      if (resetPassword === '1') {
        this.resetModalConfig.visible = true;
      }
    },
    handleCollapsedChange(state) {
      this.collapsed = state;
    },
    handleClick(item) {
      this.turnToPage(item);
    },
    turnToPage(route, all) {
      let {
        path, name, params, query,
      } = {};
      if (typeof route === 'string' && !all) path = route;
      else if (typeof route === 'string' && all) name = route;
      else {
        path = route.path;
        name = route.name;
        params = route.params;
        query = route.query;
      }
      this.$router.push({
        path,
        name,
        params,
        query,
      });
    },

    handleCloseTag(res, type, route) {
      if (type !== 'others') {
        if (type === 'all') {
          this.turnToPage('home', 'all');
        } else if (routeEqual(this.$route, route)) {
          this.closeTag(route);
        }
      }
      this.setTagNavList(res);
    },
    /**
     * 关闭修改密码弹窗
     */
    closeResetModal() {
      this.resetModalConfig.visible = false;
    },
  },
};
</script>

<style lang="less">
  .layout-right {
    width: calc(100vw - 240px);
  }
.logo {
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  background-image: url("../../assets/img/logo.png");
}

.el-header{
  height: 55px !important;
}
.el-main {
  z-index: 2;
  height: calc(100vh - 55px);
  background: #f6f7fb;
  /* // padding: 0 1rem; */
  overflow: hidden;
  padding: 0;
  box-sizing: border-box;

  .content {
    padding: 0px 8px 0px 8px;
    background: #fff;
    // min-height: calc(100vh - 135px);
    min-height: calc(100vh - 75px);
    box-sizing: border-box;
    overflow-y: scroll;

    > .el-scrollbar {
      height: 100%;
      padding-bottom: 10px;
      box-sizing: border-box;
    }
  }

  .el-footer {
    height: 60px !important;
    font-size: 0.8rem;
    padding-top: 10px;
    text-align: center;
    box-sizing: border-box;
  }

  .el-menu {
    border-right: none !important;
  }
}

.content-scrollbar {
  // max-height: calc(100vh - 115px);
  // height: calc(100vh - 115px);
  max-height: calc(100vh - 55px);
  height: calc(100vh - 55px);
  overflow-x: hidden;
  box-sizing: border-box;
}

.content-container {
  background: #fff;
  margin: 10px;
  height: calc(100vh - 85px);
}

.tag-nav-container{
  padding-right: 10px;
  flex: 1;
  display: flex;
  z-index: 0;
}
.tag-nav-wrapper{
  flex: 1;
}
.container{
  padding-top: 5px!important;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    {
      attrs: {
        native: false,
        "wrap-class": "menu-scrollbar",
        "view-class": "menu-container",
        noresize: false,
        tag: "div",
      },
    },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-vertical-demo",
          staticStyle: { "border-right": "none" },
          attrs: {
            collapse: _vm.collapsed,
            "background-color": "#1b1d46",
            "text-color": "#fff",
            "active-text-color": "#fff",
            "unique-opened": "",
            router: "",
            "default-active": _vm.currentPath,
          },
        },
        [_c("SubItem", { attrs: { list: _vm.menuList } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
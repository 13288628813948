<template>
  <!-- <SvgIcon icon-class="table"
           @click.native="handleChange" /> -->
  <i :class="classObject" style="cursor:pointer; margin-right: 10px;display: none" @click="handleChange"></i>
</template>

<script>
export default {
  name: 'SiderTrigger',
  props: {
    collapsed: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    classObject() {
      return {
        'el-icon-s-fold': !this.collapsed,
        'el-icon-s-unfold': this.collapsed,
      };
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', !this.collapsed);
    },
  },
};
</script>

<style lang="less" scoped>
  .svg-icon {
    cursor: pointer;
  }
</style>

<template>
  <div>
    <a-modal v-model="modelVisible" width="700px" title="修改密码" :closable="false" :footer="null">
      <div class="tip-text">
        <span>您好，{{ userName }}用户</span>
        <span>检测到您的密码已经被重置，为了您的账户安全，请重新设置密码</span>
      </div>
      <div class="form-container">
        <el-form
          :model="formData"
          ref="numberValidateForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="新密码" prop="newPassword">
            <div class="form-item">
              <PasswordInput
                class="form-input"
                v-model="formData.newPassword"
                placeholder="请输入新密码"
                autocomplete="off"
                type="password"
                @input="pwdLevelChange"
              ></PasswordInput>
              <PwdLevel
                @change="pwdLevelChange"
                class="pwdlevel"
                :password="formData.newPassword"
              />
            </div>
            <span class="pwd-tip">{{ pwdTip }}</span>
          </el-form-item>
          <el-form-item label="确认密码" prop="comfirmPassword">
            <div class="form-item">
              <PasswordInput
                v-model="formData.comfirmPassword"
                type="password"
                class="form-input"
                placeholder="请确认密码"
                autocomplete="off"
              ></PasswordInput>
              <span></span>
              <!-- <PwdLevel class="pwdlevel" :password="formData.newPassword" /> -->
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer">
        <el-button
          class="ok-btn"
          style="width: 100px"
          type="primary"
          @click="onOk"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import Storage from '../../../utils/storage';
import request from '../../../utils/request';
import PwdLevel from '../../password_level';
import PasswordInput from '../../form/components/form_password';
import { encodeKey128 } from '../../../utils/index';

export default {
  props: {
    config: {
      type: Object,
      default: () => ({ visible: false }),
    },
  },
  components: {
    PwdLevel,
    PasswordInput,
  },
  data() {
    return {
      formData: {
        newPassword: '',
        comfirmPassword: '',
      },
      btnLoading: false,
      pwdTip: '',
      isChecked: false,
      errorMsg: '',
    };
  },
  computed: {
    modelVisible: {
      get() {
        return this.config.visible;
      },
      set(v) {
        this.$emit('input', { ...this.config, visible: v });
      },
    },
    userName() {
      return JSON.parse(Storage.l.get('userInfo')).fullName;
    },
  },
  mounted() {},
  methods: {
    /**
     * 确定事件
     */
    onOk() {
      if (this.formData.newPassword !== this.formData.comfirmPassword) {
        this.$message.error('两次输入的密码不一样，请重新输入');
        return false;
      }
      if (!this.isChecked) {
        this.$message.error(this.errorMsg);
        return false;
      }
      this.btnLoading = true;
      this.formData.pazjofxkyvyoosot = encodeKey128(this.formData.comfirmPassword);
      const submitFormData = JSON.parse(JSON.stringify(this.formData));
      delete submitFormData.newPassword;
      delete submitFormData.comfirmPassword;
      request
        .post('/mdm/mdmUserController/resetMyselfPassword', {
          ...submitFormData,
        })
        .then((res) => {
          this.btnLoading = false;
          if (res.success) {
            this.$emit('closeModal');
            this.$message.success('修改成功');
            Storage.s.set('resetPassword', '');
          }
        })
        .catch((e) => {
          this.btnLoading = false;
        });
    },
    /**
     * 密码框input事件
     */
    pwdLevelChange(val) {
      this.pwdTip = this.pwdTip || val.words;
      this.isChecked = val.isChecked;
      this.errorMsg = this.errorMsg || val.errorMsg;
    },
  },
};
</script>

<style lang="less" scoped>
.tip-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 30px;
  span {
    font-size: 14px;
    color: #333333;
    line-height: 30px;
  }
}
.pwd-tip {
  font-size: 12px;
  line-height: 30px;
  color: #969696;
}
.form-item {
  display: flex;
  align-items: center;
}
.form-input {
  width: 370px;
  margin-right: 20px;
}
.pwdlevel {
  width: 150px;
}
.footer {
  width: 100%;
  margin-top: 60px;
  text-align: center;
}
/deep/ .el-progress__text {
  font-size: 14px !important;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    class: _vm.classObject,
    staticStyle: { cursor: "pointer", "margin-right": "10px", display: "none" },
    on: { click: _vm.handleChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
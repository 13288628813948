var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.list, function (item, index) {
        return [
          item.children && item.children.length > 0
            ? _c(
                "el-submenu",
                { key: index, attrs: { index: item.id } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { class: item.functionIcon || "el-icon-folder" }),
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(_vm._s(item.functionName)),
                    ]),
                  ]),
                  _c("subItem", { attrs: { list: item.children } }),
                ],
                2
              )
            : [
                item.functionName != "首页"
                  ? _c(
                      "el-menu-item",
                      {
                        key: index,
                        attrs: {
                          index: item.functionUrl
                            ? item.functionUrl.startsWith("http")
                              ? ""
                              : item.functionUrl
                            : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.customerUrl(item.functionUrl)
                          },
                        },
                      },
                      [
                        _c("i", {
                          class: item.functionIcon || "el-icon-tickets",
                        }),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(item.functionName))]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c("el-popover", { attrs: { placement: "bottom", trigger: "hover" } }, [
        _c("div", { staticClass: "user-info" }, [
          _c("div", { staticClass: "user-info-label" }, [
            _c("span", [_vm._v("用户名：")]),
            _c("span", [_vm._v("登录账号：")]),
            _c("span", [_vm._v("所属组织：")]),
            _c("span", [_vm._v("当前职位：")]),
          ]),
          _c("div", { staticClass: "user-info-value" }, [
            _c("span", [_vm._v(_vm._s(_vm.userInfo && _vm.userInfo.fullName))]),
            _c("span", [_vm._v(_vm._s(_vm.userInfo && _vm.userInfo.userName))]),
            _c("span", [_vm._v(_vm._s(_vm.userInfo && _vm.userInfo.orgName))]),
            _c("span", [
              _vm._v(_vm._s(_vm.userInfo && _vm.userInfo.positionName)),
            ]),
          ]),
        ]),
        _c(
          "span",
          {
            staticClass: "el-dropdown-link",
            attrs: { slot: "reference" },
            slot: "reference",
          },
          [
            _c(
              "span",
              {
                staticClass: "usertitle",
                staticStyle: { "padding-right": "4px" },
              },
              [_vm._v(_vm._s(_vm.userName))]
            ),
          ]
        ),
      ]),
      _c(
        "el-dropdown",
        { attrs: { trigger: "click" }, on: { command: _vm.handleCommand } },
        [
          _c("i", { staticClass: "el-icon-setting", attrs: { size: "16" } }),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "changePassword" } }, [
                _c("i", { staticClass: "el-icon-edit" }),
                _vm._v("修改密码"),
              ]),
              _c(
                "el-dropdown",
                {
                  attrs: { placement: "left", trigger: "click" },
                  on: { command: _vm.handleCommandPosition },
                },
                [
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "switchPosition" } },
                    [
                      _c("i", { staticClass: "el-icon-sort" }),
                      _vm._v("切换职位"),
                    ]
                  ),
                  _vm.userInfo && _vm.userPosition.length
                    ? _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        _vm._l(_vm.userPosition, function (item) {
                          return _c(
                            "fragment",
                            { key: item.positionCode },
                            [
                              item.positionCode
                                ? _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: item.positionCode,
                                        disabled:
                                          item.positionCode ===
                                            _vm.userInfo.positionCode ||
                                          item.enableStatus === "003",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.positionName))]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("el-dropdown-item", { attrs: { command: "logout" } }, [
                _c("i", { staticClass: "el-icon-switch-button" }),
                _vm._v("退出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("resetModal", {
        attrs: { config: _vm.resetModalConfig },
        on: { closeModal: _vm.closeResetModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="user">
    <el-popover placement="bottom" trigger="hover">
      <div class="user-info">
        <div class="user-info-label">
          <span>用户名：</span>
          <span>登录账号：</span>
          <span>所属组织：</span>
          <span>当前职位：</span>
        </div>
        <div class="user-info-value">
          <span>{{userInfo && userInfo.fullName}}</span>
          <span>{{ userInfo && userInfo.userName }}</span>
          <span>{{ userInfo && userInfo.orgName }}</span>
          <span>{{ userInfo && userInfo.positionName }}</span>
        </div>
      </div>
      <!--
    <SvgIcon icon-class="user" />
     <div class="img">
      <SvgIcon icon-class="user" />
    </div> -->
      <span class="el-dropdown-link" slot="reference">
        <span class="usertitle" style="padding-right: 4px">{{ userName }}</span>
      </span>
    </el-popover>
    <el-dropdown @command="handleCommand" trigger="click">
      <i class="el-icon-setting" size="16" />
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="changePassword"
          ><i class="el-icon-edit" />修改密码</el-dropdown-item
        >
        <el-dropdown @command="handleCommandPosition" placement="left" trigger="click">
          <el-dropdown-item command="switchPosition">
            <i class="el-icon-sort" />切换职位</el-dropdown-item
          >
          <el-dropdown-menu slot="dropdown" v-if="userInfo && userPosition.length">
            <fragment v-for="item in userPosition" :key="item.positionCode" >
            <el-dropdown-item  v-if="item.positionCode" :command="item.positionCode" :disabled="item.positionCode ===
            userInfo.positionCode || item.enableStatus==='003'"> {{item.positionName}}</el-dropdown-item>
            </fragment>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown-item command="logout">
          <i class="el-icon-switch-button" />退出</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <!-- <Modal @onClose="closeModal" :modalConfig="modalConfig">
      <Form @onClose="closeModal" />
    </Modal> -->
    <resetModal :config="resetModalConfig" @closeModal="closeResetModal"/>
  </div>
</template>
<script>

// import Modal from '../../../modal';
// import Form from './form/form.vue';
import { logout } from '../../../../utils';
import request from '../../../../utils/request';
import Storage from '../../../../utils/storage';
import resetModal from './form/index';

export default {
  name: 'UserManage',
  components: {
    // Modal,
    // Form,
    resetModal,
  },
  inject: ['reload'],
  data() {
    return {
      userName: '',
      modalConfig: {
        title: '修改密码',
        visible: false,
        type: 'modal',
      },
      userInfo: null,
      userPosition: [],
      resetModalConfig: {
        visible: false,
      },
    };
  },
  created() {
    // this.userName = '廖彬彬';
    if (this.getUser()) {
      this.userName = JSON.parse(this.getUser()).fullName;
    }
    this.getUserInfo();
    this.getUserPosition();
    this.getLoginInfo();
  },
  methods: {
    // 获取登录信息
    getLoginInfo() {
      const loginInfo = this.$store.state.app.lastLoginInfo;
      const userInfo = JSON.parse(Storage.l.get('userInfo'));
      // if (loginInfo && Object.keys(loginInfo).length > 0) {
      if (userInfo) {
        const h = this.$createElement;
        this.$notify({
          title: '登录信息',
          dangerouslyUseHTMLString: true,
          duration: 3000,
          message: h('div', {}, [
            // h('div', {}, `登录ip：${loginInfo.lastLoginLog.clientIp}`),
            // h('div', `登录地址：${loginInfo.lastLoginLog.clientLocationArea || ''}`),
            // h('div', `登录时间：${loginInfo.lastLoginLog.loginDateTime || ''}`),
            // h('div', `登录入口：${loginInfo.lastLoginLog.fromTypeName || ''}`),
            h('div', `用户名：${userInfo.fullName || ''}`),
            h('div', `登录账号：${userInfo.userName || ''}`),
            h('div', `当前职位：${userInfo.positionName || ''}`),
            h('div', `所属组织：${userInfo.orgName || ''}`),
            h('div', {}, ['如果不是本人登录：', h('a', { on: { click: () => this.handleCommand('changePassword') } }, '修改密码')])]),
          position: 'bottom-right',
        });
        this.$store.commit('setLastLoginInfo', {});
      }
    },
    getUser() {
      return localStorage.getItem('userInfo');
    },
    handleCommand(command) {
      switch (command) {
        case 'changePassword':
          this.resetModalConfig.visible = true;
          break;
        case 'logout':
          request.post('/mdm/mdmSystemLoginController/logout').then((res) => {
            if (res.success) {
              logout();
              this.$router.push('/login');
            }
          });
          // location.reload();
          break;
        default:
          console.log('no such command');
          break;
      }
    },
    // 切换职位
    handleCommandPosition(command) {
      if (command === 'switchPosition') return false;
      request.post('/mdm/mdmPositionController/changeUserCurrentPosition', {
        userName: JSON.parse(Storage.l.get('userInfo')).userName,
        positionCode: command,
      }).then((res) => {
        if (res.success) {
          // console.log(JSON.parse(localStorage.userInfo));
          this.userInfo.positionCode = command;
          this.$store.dispatch('menus/getMenusNavList').then(() => {
            this.$router.replace('/');
            setTimeout(() => {
              this.reload();
              const tagsNav = this.$store.state.app.tagNavList;
              this.$store.commit('setTagNavList', tagsNav.filter((item) => item.name === 'home'));
              this.$message.success('切换成功');
            }, 100);
          });
        }
      });
    },
    // 获取用户信息
    getUserInfo() {
      request.get('/mdm/mdmUserController/getCurUserPositionDetail').then((res) => {
        if (res.success) {
          this.userInfo = res.result;
          const userData = JSON.parse(Storage.l.get('userInfo'));
          Storage.l.set('userInfo', JSON.stringify({
            ...userData,
            userPhone: res.result.userPhone,
          }));
          localStorage.setItem('userInfo', JSON.stringify(res.result));
          sessionStorage.setItem('userInfo', JSON.stringify(res.result));
          // console.log(Storage.l.userInfo);
        }
      });
    },
    // 获取 用户全部职位
    getUserPosition() {
      request.post('/mdm/mdmPositionController/getUserAllPositionList', {
        userName: JSON.parse(Storage.l.get('userInfo')).userName,
      }).then((res) => {
        if (res.success) {
          this.userPosition = res.result;
        }
      });
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig.visible = false;
    },
    /**
     * 关闭修改密码弹窗
     */
    closeResetModal() {
      this.resetModalConfig.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.user-info {
  display: flex;
  span {
    line-height: 20px;
  }
  .user-info-label {
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .user-info-value {
    display: flex;
    flex-direction: column;
  }
}
.user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  // .img {
  //   padding: 10px;
  //   width: 0.24rem;
  //   height: 0.24rem;
  //   line-height: 0.24rem;
  //   border-radius: 50%;
  //   .svg-icon {
  //     width: 0.18rem;
  //     height: 0.18rem;
  //   }
  // }
  .el-dropdown {
    // color: #ffffff;
    cursor: pointer;
  }

  .el-dropdown-link {
    //opacity: 0.6;
    display: flex;
    align-items: center;
    cursor: pointer;
    .svg-icon {
      width: 1rem;
      height: 1rem;
    }
    .usertitle {
      padding-left: 0.05rem;
    }
  }
}
</style>

<template>
  <div class="header">
    <div class="header-left">
      <SiderTrigger @change="handleCollpasedChange" :collapsed="collapsed"/>
      <slot name="left" />
    </div>
    <div class="header-right">
      <slot name="right" />
      <UserManage />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SiderTrigger from './sider_trigger';
import UserManage from './user_manage';

export default {
  components: {
    SiderTrigger,
    UserManage,
  },
  computed: {
    ...mapGetters({
      theme: 'theme',
    }),
  },
  props: {
    collapsed: Boolean,
  },
  methods: {
    ...mapActions({
      changeSetting: 'theme/changeSetting',
    }),
    handleCollpasedChange(state) {
      this.$emit('trigger-change', state);
    },
    /**
     * @description 主题色改变
     * @param { String } val 色值
     */
    themeChange(val) {
      const self = this;
      self.changeSetting({
        key: 'theme',
        value: val,
      });
    },
  },
};
</script>

<style lang="less">
.header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-left {
    display: flex;
    align-items: center;
    flex: 1;
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".layout-right {\n  width: calc(100vw - 240px);\n}\n.logo {\n  height: 60px;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: 90%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.el-header {\n  height: 55px !important;\n}\n.el-main {\n  z-index: 2;\n  height: calc(100vh - 55px);\n  background: #f6f7fb;\n  /* // padding: 0 1rem; */\n  overflow: hidden;\n  padding: 0;\n  box-sizing: border-box;\n}\n.el-main .content {\n  padding: 0px 8px 0px 8px;\n  background: #fff;\n  min-height: calc(100vh - 75px);\n  box-sizing: border-box;\n  overflow-y: scroll;\n}\n.el-main .content > .el-scrollbar {\n  height: 100%;\n  padding-bottom: 10px;\n  box-sizing: border-box;\n}\n.el-main .el-footer {\n  height: 60px !important;\n  font-size: 0.8rem;\n  padding-top: 10px;\n  text-align: center;\n  box-sizing: border-box;\n}\n.el-main .el-menu {\n  border-right: none !important;\n}\n.content-scrollbar {\n  max-height: calc(100vh - 55px);\n  height: calc(100vh - 55px);\n  overflow-x: hidden;\n  box-sizing: border-box;\n}\n.content-container {\n  background: #fff;\n  margin: 10px;\n  height: calc(100vh - 85px);\n}\n.tag-nav-container {\n  padding-right: 10px;\n  flex: 1;\n  display: flex;\n  z-index: 0;\n}\n.tag-nav-wrapper {\n  flex: 1;\n}\n.container {\n  padding-top: 5px!important;\n}\n", ""]);
// Exports
module.exports = exports;

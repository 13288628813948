<template>
  <el-scrollbar
    :native="false"
    wrap-class="menu-scrollbar"
    view-class="menu-container"
    :noresize="false"
    tag="div"
  >
    <el-menu
      class="el-menu-vertical-demo"
      :collapse="collapsed"
      style="border-right: none"
      background-color="#1b1d46"
      text-color="#fff"
      active-text-color="#fff"
      unique-opened
      router
      :default-active="currentPath"
    >
      <SubItem :list="menuList"></SubItem>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import SubItem from './subItem.vue';

export default {
  props: {
    collapsed: Boolean,
    menuList: Array,
  },
  data() {
    return {
      currentPath: this.$route.fullPath,
    };
  },
  components: {
    SubItem,
  },
  created() {
    this.getFunctionCode();
  },
  watch: {
    $route(to) {
      this.currentPath = to.fullPath;
      this.getFunctionCode();
    },
  },
  methods: {
    // 获取当前路由的functionCode
    getFunctionCode() {
      const menus = this.$store.state.menus.menusName;
      this.getCurrentMenu(menus, this.currentPath);
    },
    // 查找当前路由的数据
    getCurrentMenu(menus, currentPath) {
      let funcitonCode = '';
      for (const v of menus) {
        const item = v;
        if (item.children && item.children.length > 0) {
          this.getCurrentMenu(item.children, currentPath);
        }
        const functionUrl = item.functionUrl && item.functionUrl.split('?')[0];
        if (currentPath.split('?')[0] === functionUrl) {
          funcitonCode = item.functionCode;
          this.$store.commit('menus/setselectMenu', funcitonCode);
          return;
        }
      }
    },
  },
};
</script>

<style lang="less">
.menu-container {
  max-height: calc(100vh - 60px);
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}

.el-menu-item.is-active {
  background-color: #5895ff ;
}
</style>
